<template>
  <promised :promise="promise">
    <template #pending>
      <b-loading 
        :active="true"
        :is-full-page="false"
      />
    </template>
    <template #default>
      <breadcrumb :items="breadcrumbs" />
      <hero>
        {{ game.title }}
        <template slot="right">
          <b-button
            v-if="!game.hosted"
            type="is-link"
            tag="a"
            class="mr-4"
            target="_blank"
            :href="`https://html5.gamedistribution.com/${game.md5}/`"
          >
            Game Distribution
          </b-button>
          <switch-locale
            v-model="locale"
            @change="handleLocaleChange"
          />
        </template>
      </hero>
      <section class="section is-main-section">
        <card
          :title="game.title"
          icon="pencil-outline"
        >
          <tabs v-model="activeTab">
            <template #items>
              <b-tab-item
                value="data"
                label="Data"
                icon="database"
              >
                <b-message
                  v-if="missingCategoryTranslations && missingCategoryTranslations.length"
                  title="Missing category translations" 
                  type="is-info" 
                  aria-close-label="Close message"
                >
                  <p
                    v-for="category in missingCategoryTranslations" 
                    :key="category.slug"
                  >
                    <router-link
                      :to="{ name: 'CategoriesEdit', params: { category: category.distribution_id }, query: { language: locale } }" 
                      target="_blank"
                    >
                      {{ category.title }}
                    </router-link>
                  </p>
                </b-message>
                <validation-observer 
                  v-slot="{ handleSubmit, pristine }"
                  ref="form"
                  tag="form"
                >
                  <input-with-validation
                    v-model="form.title"
                    name="title"
                    rules="required|max:255"
                    label="Title"
                    icon="format-title"
                    maxlength="255"
                    horizontal
                    @input="createSlug"
                  />
                  <input-with-validation
                    v-model="form.description"
                    name="description"
                    rules="required"
                    label="Description"
                    type="textarea"
                    horizontal
                  />
                  <input-with-validation
                    v-model="form.instructions"
                    name="instructions"
                    label="Instructions"
                    type="textarea"
                    placeholder="Optional"
                    horizontal
                  />
                  <input-with-validation
                    v-model="form.controls"
                    name="controls"
                    label="Controls"
                    type="textarea"
                    placeholder="Optional"
                    horizontal
                  />
                  <input-with-validation
                    v-model="form.slug"
                    name="slug"
                    rules="required|max:255|slug"
                    label="Slug"
                    icon="link"
                    maxlength="255"
                    horizontal
                  />
                  <category-input-with-validation
                    v-if="isDefaultLocale"
                    v-model="form.categories"
                    name="categories"
                    rules="required"
                    label="Categories"
                    horizontal
                  />
                  <date-picker-with-validation
                    v-if="!form.active"
                    v-model="form.published_at"
                    name="published_at"
                    label="Published at"
                    horizontal
                  />
                  <input-with-validation
                    v-if="isDefaultLocale"
                    v-model="form.width"
                    name="width"
                    rules="required"
                    label="Width"
                    type="number"
                    icon="arrow-expand-horizontal"
                    horizontal
                  />
                  <input-with-validation
                    v-if="isDefaultLocale"
                    v-model="form.height"
                    name="height"
                    rules="required"
                    label="Height"
                    type="number"
                    icon="arrow-expand-vertical"
                    horizontal
                  />
                  <input-with-validation
                    v-if="isDefaultLocale"
                    v-model="form.wikipedia_url"
                    name="wikipedia_url"
                    label="Wikipedia"
                    icon="link"
                    placeholder="Optional"
                    maxlength="255"
                    horizontal
                  />
                  <input-with-validation
                    v-if="isDefaultLocale"
                    v-model="form.fandom_url"
                    name="fandom_url"
                    label="Fandom"
                    icon="link"
                    placeholder="Optional"
                    maxlength="255"
                    horizontal
                  />
                  <input-with-validation
                    v-if="isDefaultLocale"
                    v-model="form.youtube_video_id"
                    name="youtube_video_id"
                    label="YouTube Video ID"
                    icon="link"
                    placeholder="Optional"
                    maxlength="255"
                    horizontal
                  />
                  <select-with-validation
                    v-if="isDefaultLocale"
                    v-model="form.developer_id"
                    name="developer_id"
                    label="Developer"
                    horizontal
                  >
                    <option value="">
                      None
                    </option>
                    <option
                      v-for="item in companies"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select-with-validation>
                  <select-with-validation
                    v-if="isDefaultLocale"
                    v-model="form.featured"
                    name="featured"
                    label="Featured"
                    horizontal
                  >
                    <option :value="1">
                      Yes
                    </option>
                    <option :value="0">
                      No
                    </option>
                  </select-with-validation>
                  <b-field
                    v-if="isDefaultLocale && mappedAssets.length"
                    label="Assets"
                    horizontal
                  >
                    <div class="control">
                      <image-gallery-slideshow :data="mappedAssets" />
                    </div>
                  </b-field>
                  <upload-with-validation
                    v-if="isDefaultLocale"
                    v-model="form.icon"
                    name="icon"
                    rules="ext:jpg,jpeg,png,webp"
                    accept=".jpg,.jpeg,.png,.webp"
                    horizontal
                  >
                    Click to upload (icon)
                  </upload-with-validation>
                  <upload-with-validation
                    v-if="isDefaultLocale"
                    v-model="form.cover"
                    name="cover"
                    rules="ext:jpg,jpeg,png,webp"
                    accept=".jpg,.jpeg,.png,.webp"
                    horizontal
                  >
                    Click to upload (cover)
                  </upload-with-validation>
                  <hr>
                  <b-field horizontal>
                    <b-field grouped>
                      <div class="control">
                        <b-button 
                          type="is-primary"
                          :disabled="pristine || loading"
                          @click="handleSubmit(submit)"
                        >
                          Submit
                        </b-button>
                      </div>
                    </b-field>
                  </b-field>
                </validation-observer>
              </b-tab-item>
              <b-tab-item
                v-if="isDefaultLocale"
                value="advanced"
                label="Advanced"
                icon="cog"
              >
                <b-message 
                  type="is-danger"
                  has-icon
                >
                  <p><b>Delete this game</b></p>
                  <p>This action deletes the game distribution and every asset associated with it including translations.</p>
                  <b-button 
                    type="is-danger"
                    @click="confirmDelete"
                  >
                    Delete game
                  </b-button>
                </b-message>
              </b-tab-item>
            </template>
          </tabs>
        </card>
      </section>
      <b-loading 
        :active="loading" 
        :is-full-page="false" 
      />
    </template>
    <template #rejected>
      <section class="section is-main-section">
        <div class="content has-text-grey has-text-centered">
          <b-icon 
            icon="emoticon-sad" 
            size="is-large" 
          />
          <p>Something went wrong</p>
        </div>
      </section>
    </template>
  </promised>
</template>

<script>
import slugify from "slugify";
import moment from "moment";
import omit from "lodash/omit";
import { mapGetters, mapActions } from "vuex";
import { Promised } from "vue-promised";
import { ValidationObserver } from "vee-validate";
import availablelocales from "@/common/locales";
import pluck from "@/utils/pluck";
import buildFormDataMixin from "@/mixins/buildFormDataMixin";
import Breadcrumb from "@/components/Breadcrumb";
import Hero from "@/components/Hero";
import SwitchLocale from "@/components/SwitchLocale";
import Card from "@/components/Card";
import Tabs from "@/components/Tabs";
import InputWithValidation from "@/components/Validation/InputWithValidation";
import DatePickerWithValidation from "@/components/Validation/DatePickerWithValidation";
import SelectWithValidation from "@/components/Validation/SelectWithValidation";
import UploadWithValidation from "@/components/Validation/UploadWithValidation";
import CategoryInputWithValidation from "@/components/Validation/CategoryInputWithValidation";
import ImageGallerySlideshow from "@/components/ImageGallerySlideshow";
import Repository from "@/repositories/Repository";

const GameRepository = Repository.get('games');

export default {
  name: 'GamesEdit',

  components: {
    Promised,
    ValidationObserver,
    Breadcrumb,
    Hero,
    SwitchLocale,
    Card,
    Tabs,
    InputWithValidation,
    DatePickerWithValidation,
    SelectWithValidation,
    UploadWithValidation,
    CategoryInputWithValidation,
    ImageGallerySlideshow,
  },

  mixins: [buildFormDataMixin],
  
  data() {
    return {
      promise: null,
      breadcrumbs: [
        'Admin', 
        'Edit Game',
      ],
      activeTab: undefined,
      loading: false,
      locale: '',
      locales: [],
      game: {},
      form: {},
    };
  },

  computed: {
    ...mapGetters('config', ['defaultApiLocale']),
    ...mapGetters('data', ['companies']),

    isDefaultLocale() {
      return this.locale === this.defaultApiLocale;
    },

    isEdit() {
      return this.locales.includes(this.locale);
    },

    mappedAssets() {
      return this.form.assets && this.form.assets.map(asset => ({
        src: `${process.env.VUE_APP_CDN_URL}/game-icons/${asset.name}`,
        width: asset.width,
        height: asset.height,
      }));
    },

    missingCategoryTranslations() {
      return !this.isDefaultLocale && this.form.categories && this.game.categories.filter(locale => !this.form.categories.some(translation => locale.distribution_id === translation.distribution_id ));
    },
  },

  created() {
    this.locale = this.defaultApiLocale;
    this.locales.push(this.locale);
    this.promise = this.fetchGame(this.locale);
    this.promise.then(response => {
      const { title, categories, md5, hosted } = response.data;
      this.game = {
        title,
        categories,
        md5,
        hosted,
      };
      Object.keys(response.alternates || []).forEach(alternate => {
        this.locales.push(alternate);
      });
    }).then(() => {
      if (this.$route.query.language && availablelocales.find(locale => locale.code === this.$route.query.language)) {
        this.handleLocaleChange(this.$route.query.language);
      }
    });
  },

  methods: {
    ...mapActions('data', {
      editGameAction: 'editGame',
      deleteGameAction: 'deleteGame',
    }),

    createSlug(value) {
      this.form.slug = slugify(value, {
        lower: true,
        strict: true,
      });
    },

    async fetchGame(locale) {
      const response = await GameRepository.find(this.$route.params.game, {
        language: locale,
        state: 'preview',
      });

      const {
        id,
        title, 
        description,
        instructions,
        controls,
        slug,
        active,
        published_at,
        categories,
        width,
        height,
        assets,
        wikipedia_url,
        fandom_url,
        youtube_video_id,
        developer,
        featured,
      } = response.data;

      this.form = Object.assign({
        id,
        title,
        description,
        instructions: instructions || '',
        controls: controls || '',
        slug,
        active: active || 0,
        ...(!active && {
          published_at: published_at ? new Date(published_at) : null,
        }),
        categories,
      }, this.isDefaultLocale ? {
        width,
        height,
        assets: assets
                ? Object.entries(assets).flatMap(([key, value]) =>
                    key === "cover" ? [value] : Object.values(value)
                  )
                : [],
        wikipedia_url: wikipedia_url || '',
        fandom_url: fandom_url || '',
        youtube_video_id: youtube_video_id || '',
        developer_id: developer ? developer.id : '',
        featured: featured || 0,
        icon: null,
        cover: null,
      } : {});

      return response;
    },

    handleLocaleChange(locale) {
      this.activeTab = undefined;
      this.locale = locale;
      this.locales.includes(this.locale) ? this.promise = this.fetchGame(this.locale) : this.resetForm();
    },
    
    async editGame() {
      Object.keys(this.form).forEach((key) => (this.form[key] === null) && delete this.form[key]);

      const form = this.isDefaultLocale ? this.form : omit(this.form, 'categories');

      const response = await this.editGameAction(this.buildFormData(Object.assign({}, form, {
        ...(!form.active && {
          published_at: form.published_at ? moment(form.published_at).format('YYYY-MM-DD') : '',
        }),
        ...(this.isDefaultLocale && {
          categories: form.categories.map(category => category.distribution_id),
        }),
        language: this.locale,
        _method: 'PATCH',
      })));

      if (this.isDefaultLocale) {
        this.$set(this.game, 'categories', response.data.categories);
      }

      if (this.isDefaultLocale && response.data.assets) {
        this.$set(this.form, 'assets', Object.values(response.data.assets).flat());
      }

      if (process.env.NODE_ENV === 'production') {
        let purgeUrls = [];
        if (this.form.icon || this.form.cover) {
          purgeUrls = purgeUrls.concat(
            pluck(this.mappedAssets, "src"),
            pluck(this.mappedAssets, "src").map((url) =>
              url.replace(".jpg", ".webp")
            )
          );
        }
        purgeUrls.push(`${process.env.VUE_APP_API_URL}/games/${this.form.slug}?language=${this.locale}`);
        Repository.get('purge').store(purgeUrls);
      }
      
      return Promise.resolve(response);
    },

    async translateGame() {
      const response = await GameRepository.store(this.buildFormData(Object.assign({}, this.form, {
        published_at: this.form.published_at ? moment(this.form.published_at).format('YYYY-MM-DD') : '',
        language: this.locale,
        game_id: this.$route.params.game,
      })));

      this.locales.push(this.locale);

      this.$set(this.form, 'id', response.data.id);
      this.$set(this.form, 'categories', response.data.categories);
      
      return Promise.resolve(response);
    },

    deleteGame() {
      this.loading = true;
      this.deleteGameAction(this.buildFormData({
        id: this.$route.params.game,
        _method: 'DELETE',
      })).then(() => {
        this.$buefy.toast.open({
          message: 'Deleted successfully',
          type: 'is-success',
          position: 'is-bottom-right',
        });
        this.$router.push({ 
          name: 'Games', 
        });
      }).catch(err => {
        this.$buefy.toast.open({
          message: err.message,
          type: 'is-warning',
          position: 'is-bottom-right',
        });
      }).finally(() => {
        this.loading = false;
      });;
    },

    submit() {
      this.loading = true;
      const promise = this.isEdit ? this.editGame() : this.translateGame();
      promise.then(() => {
        this.$buefy.toast.open({
          message: this.isEdit ? 'Edited successfully' : 'Created successfully',
          type: 'is-success',
          position: 'is-bottom-right',
        });
      }).catch(err => {
        this.$buefy.toast.open({
          message: err.message,
          type: 'is-warning',
          position: 'is-bottom-right',
        });
        if (err.response) {
          this.$refs.form.setErrors(err.response.data.errors);
        }
      }).finally(() => {
        this.loading = false;
      });
    },

    confirmDelete() {
      this.$buefy.dialog.confirm({
        title: 'Deleting game',
        message: 'Are you sure you want to <b>delete</b> the game? This action cannot be undone.',
        animation: '',
        confirmText: 'Delete Game',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: this.deleteGame,
        focusOn: 'cancel',
      });
    },

    resetForm() {
      this.form = {};
    },
  },
};
</script>
