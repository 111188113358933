<template>
  <b-taginput
    :value="value"
    size="is-medium"
    :data="filtered"
    autocomplete
    field="title"
    icon="sitemap"
    placeholder="Add"
    aria-close-label="Delete"
    @typing="handleTyping"
    @add="handleAdd"
    @input="handleChange"
  />
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";

export default {
  name: 'CategoryInput',

  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      filtered: [],
    };
  },

  computed: {
    ...mapGetters('data', ['categories']),
  },

  methods: {
    handleChange(value) {
      this.$emit("input", value);
    },

    handleTyping: debounce(function (keyword) {
      this.filtered = this.categories.filter(category => !this.value.find(value => value.distribution_id === category.distribution_id) && category.title.toLowerCase().includes(keyword.toLowerCase()));
    }, 300),

    handleAdd() { 
      this.filtered = [];
    },
  }
};
</script>
